import axios from "@axios";
// import {ThumbsDownIcon} from "vue-feather-icons";
// const axios = require("axios");
export default function clickAnswer(answer) {
	// console.log(this.memoryAnswersComputed);

	// 	let memoryAnswers = [];
	// 	if(this.currentPage <= 15)
	// 	for(this.currentPage; this.currentPage < this.config.lastPageNo; this.currentPage++){
	// 		if(this.currentPage <= 15)
	// 			this.testRecord[this.currentPage] = {
	// 				page: this.currentPage,
	// 				answer: "0_0",
	// 				timeSpent: 30000,
	// 			};
	// 		else if(this.currentPage > 15 && this.currentPage % 2 == 0)
	// 			continue;
	// 		else if(this.currentPage > 15 && this.currentPage % 2 == 1){
	// 			for(let i = 0; i < this.currentPage - 13; i++){
	// 				memoryAnswers[i]={
	// 					page:
	// 					this.currentPage < 16
	// 					? this.currentPage
	// 					: 15 +
	// 					Math.floor((parseInt(this.currentPage) - 15) / 2),
	// 					answer: "0_0",
	// 					timeSpent: 30000,
	// 				}
	// 			}
	// 			this.testRecord[15 + Math.floor((parseInt(this.currentPage) - 15) / 2)] = memoryAnswers;
	// 			memoryAnswers = [];
	// 		}
	// 			// this.testRecord[15 + Math.floor((parseInt(this.currentPage) - 15) / 2)] = []
	// 	}
	// }
	// console.log(this.$route.params.id);
	let willPopupControl;
	console.log(answer);
	const calculatedPage =
		this.currentPage < 16
			? this.currentPage
			: 15 + Math.floor((parseInt(this.currentPage) - 15) / 2);
	if (answer != "SKIP") {
		willPopupControl =
			this.currentPage <= 5 ||
			(this.currentPage <= 15 && this.currentPage >= 11);
		if (
			(answer === "1_1" && willPopupControl) ||
			this.inTransition == true ||
			this.testPaused ||
			(this.currentPage > 15 && this.currentPage % 2 == 0 && answer != "0_0")
		)
			return;
		if (this.currentPage > 15 && this.currentPage % 2 == 0 && answer == "0_0") {
			this.inTransition = true;
			this.exitTimelines[this.currentPage].resume();
			if (!this.skipInterval)
				this.$swal({
					title: "Çözüm Ekranı Geliyor!",
					icon: "warning",
					showConfirmButton: false,
					confirmButtonText: "Tamam",
					timer: 2000,
				});
			setTimeout(() => {
				this.progressBarKey++;
				this.currentPage++;
				this.pageStartTime = Date.now();
				this.testPaused = false;
				this.pageTimeSpent = 0;
				this.animationTimelines[this.currentPage].resume();

				this.startTimer(this.config.pageTimeAllowed - 3000);
			}, this.skipInterval ? 0 : 2000);

			return;
		}
		// .add(
		//   "selected-memory-answer"
		// );
		// console.log("answer ", answer);
		// console.log("index ", Math.floor((this.currentPage - 16) / 2));
		// console.log(
		//   "array ",
		//   this.memoryAnswers[Math.floor((this.currentPage - 16) / 2)]
		// );
		// console.log(
		//   this.memoryAnswers[Math.floor((this.currentPage - 16) / 2)].some(
		//     (ans) => ans.answer == answer
		//   )
		// );
		// console.log(this.memoryAnswers);
		// if starting test
		// if (this.currentPage == 2 && answer === 9) {
		//   console.log('START TEST')
		//   this.inTransition = true
		//   this.currentPage++
		//   console.log(this.animationTimelines)
		//   this.animationTimelines[this.currentPage].resume()
		//   this.progressBarKey++
		//   this.pageStartTime = Date.now()
		//   this.testPaused = false
		//   this.pageTimeSpent = 0
		//   this.startTimer(this.config.pageTimeAllowed)
		//   return
		// }
		if (this.currentPage >= 16) {
			if (
				this.memoryAnswers[Math.floor((this.currentPage - 16) / 2)].some(
					ans => ans.answer == answer && ans.answer != "0_0",
				)
			) {
				console.log("clicked again");
				return;
			}
			if (answer != "0_0") {
				this.$refs[`ref${this.currentPage}${answer}`][0].classList.add(
					"selected-memory-answer",
				);
				this.$refs[`ref${this.currentPage}${answer}`][0].classList.remove(
					"clickablecard",
				);
			}
		}
		this.pageTimeSpent = Date.now() - this.pageStartTime; // keep track of time spent solving test
		// TODO save answer and time somewhere

		const answerObj = {
			page: calculatedPage,
			answer,
			time: this.pageTimeSpent - (willPopupControl ? 5000 : 2000),
		}; // create object to save to file
		// console.log(answerObj);

		// console.log(answerObj); // temporary code
		if (this.currentPage <= 15)
			this.testRecord[parseInt(this.currentPage)] = answerObj;
		else if (
			answer === "0_0" &&
			this.currentPage % 2 == 1 &&
			this.memoryAnswers[calculatedPage - 16].length != this.currentPage - 13
		) {
			// add answer to test record
			do {
				this.memoryAnswers[calculatedPage - 16].push(answerObj);
			} while (
				this.memoryAnswers[calculatedPage - 16].length != this.currentPage - 13
			);
			this.testRecord[calculatedPage] = this.memoryAnswers[calculatedPage - 16];
		} else if (
			this.currentPage % 2 == 1 &&
			this.memoryAnswers[calculatedPage - 16].length != this.currentPage - 13
		) {
			this.memoryAnswers[calculatedPage - 16] = [
				...this.memoryAnswers[calculatedPage - 16],
				answerObj,
			];
			// console.log("memAnswers", this.memoryAnswers);
			if (
				this.memoryAnswers[calculatedPage - 16].length !=
				this.currentPage - 13
			)
				return;
			else
				this.testRecord[calculatedPage] = this.memoryAnswers[
					calculatedPage - 16
				];
		} else
			this.testRecord[calculatedPage] = this.memoryAnswers[calculatedPage - 16]; // add answer to test record
		this.testPaused = true;
		clearTimeout(this.testTimeout); // clear timeout for stability if test wasnt skipped
		clearTimeout(this.pageTimerTimeout);
		if (answer == "0_0") this.pageTimeLeft = 0;
	}
	if (
		this.currentPage == this.config.lastPageNo
		// 4
	) {
		// ends test
		// console.log("pageCheck", this.currentPage, this.config.lastPageNo);
		// let record = [];
		// for (let i = 1; i <= Object.keys(this.testRecord).length; i++) {
		// 	record.push(this.testRecord[i]);
		// }
		// record = [...record, ...this.memoryAnswers];
		if (!this.testRecord[0]) this.testRecord.shift();
		if (!this.testRecord[this.testRecord.length - 1]) this.testRecord.pop();
		const testRecord = {
			id: this.$route.params.id,
			record: this.testRecord,
		};
		// console.log(record);
		// TODO !! end test logic
		this.$swal({
			title: "Test Tamamlandı, Gönderiliyor...",
			icon: "info",
			showConfirmButton: false,
			customClass: {
				confirmButton: "btn btn-info",
			},
			buttonsStyling: false,
		});
		console.dir(testRecord);
		if (this.skipInterval) clearInterval(this.skipInterval);
		axios.post(`/test/${this.$route.params.id}`, testRecord).then((axiosResponse) => {
			// console.log(axiosResponse);
			// let respData = axiosResponse.data;
			localStorage.removeItem("testRecord");
			this.$swal({
				title: "Test Tamamlandı!",
				icon: "success",
				confirmButtonText: "Tamam",
				// timer: 3000,
				customClass: {
					confirmButton: "btn btn-success",
				},
				buttonsStyling: false,
			}).then(() => {
				// window.open(this.$router.resolve({name:"testcompare",params:{id:this.$route.params.id,testid : respData._id}}).href, '_blank');
				this.$router.push(`/clientlist/${this.$route.params.id}`).then(() => {
					
					this.$router.go();
				});
			});
		});
		return;
	}

	// if not last page
	this.inTransition = true;
	this.exitTimelines[this.currentPage].resume();
	if (
		this.currentPage < 16 ||
		(this.currentPage > 15 &&
			this.currentPage % 2 == 1 &&
			this.memoryAnswers[calculatedPage - 16].length == this.currentPage - 13)
	) {
		if (this.currentPage >= 16) {
			// console.log("memAnswers", this.memoryAnswers);
			this.memoryAnswers[calculatedPage - 16].forEach(ans => {
				if (ans.answer != "0_0")
					this.$refs[`ref${this.currentPage}${ans.answer}`][0].classList.remove(
						"selected-memory-answer",
					);
			});
		}
		if (!this.skipInterval)
			this.$swal({
				title: `${answer != "0_0" ? "İşaretleme Yapıldı" : "Süre Doldu"}`,
				icon: `${answer != "0_0" ? "success" : "warning"}`,
				showConfirmButton: false,
				timer: 2000,
			});
	}
	if (
		this.config.breakpoints.includes(this.currentPage) &&
		!this.skipInterval
	) {
		setTimeout(() => {
			this.$emit(
				"partComplete",
				this.config.breakpoints.indexOf(this.currentPage),
			); //< use this when done
		}, 2000);
		// this.$store.commit('testTutTwoMut')
	} else {
		setTimeout(() => {
			this.progressBarKey++;
			this.currentPage++;
			this.pageStartTime = Date.now();
			this.testPaused = false;
			this.pageTimeSpent = 0;
			this.animationTimelines[this.currentPage].resume();
			this.startTimer(
				willPopupControl
					? this.config.pageTimeAllowed
					: this.config.pageTimeAllowed - 3000,
			);
		}, this.skipInterval ? 0 : 2000);
	}
}
