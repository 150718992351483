export default function skipTest() {
	// this.skipInterval = setInterval(() => {
	// 	const willPopupControl =
	// 		this.currentPage <= 5 ||
	// 		(this.currentPage <= 15 && this.currentPage >= 11);
	// 	this.testPaused = false;
	// 	this.inTransition = false;
	// 	this.pageStartTime = Date.now() - 30000 - (willPopupControl ? 3000 : 0);
	// 	this.clickAnswer("0_0");
	// 	if (this.currentPage == this.config.lastPageNo) {
	// 		clearInterval(this.skipInterval);
	// 		this.skipInterval = null;
	// 	}
	// }, 100);
	this.$swal({
		title: "Testi Sonlandır",
		text: "Testin kalan soruları boş olarak işaretlenecek ve test sonlandırılacaktır. Testi erken sonlandırmak istediğinize emin misiniz?",
		icon: "warning",
		showCancelButton: true,
		confirmButtonText: "Evet",
		cancelButtonText: "Hayır",
	}).then((result) => {
		if (result.value) {
			let emptyAnswers = Array.from({ length: 20 }, (v, index) => {
				if (index < 15)
					return {
						page: index + 1,
						answer: "0_0",
						timeSpent: 30000,
					};
				else {
					return Array.from({ length: (index - 13) * 2 }, () => {
						return {
							page: index + 1,
							answer: "0_0",
							timeSpent: 30000,
						};
					});
					// {
					// 	page: index + 1,
					// 	answer: Array.from({ length: (index - 14) * 2 }, () => "0_0"),
					// 	timeSpent: Array.from({ length: (index - 14) * 2 }, () => 30000),
					// };
				}
			});
			// console.log(emptyAnswers);
			// console.log(this.testRecord);
			if (!this.testRecord[0]) this.testRecord.shift();
			if (!this.testRecord[this.testRecord.length - 1]) this.testRecord.pop();
			this.testRecord = [
				...this.testRecord,
				...emptyAnswers.splice(this.testRecord.length),
			];
			this.currentPage = this.config.lastPageNo;
			this.clickAnswer("SKIP");
		} else if (result.dismiss === this.$swal.DismissReason.cancel) {
			return;//this.$swal("Cancelled", "Your answers are safe :)", "error");
		}
	});
	
}
