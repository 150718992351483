export default function importAll() {
  let page = [];
  let current_page = 1;
  for (let i = 1; i <= this.config.lastPageNo; i++) {
    let temp = [];
    for (let x = 1; x <= this.config.rows; x++) {
      for (let y = 1; y <= this.config.cols; y++) {
        temp.push(
          require(`@/assets/test-images/${current_page}/${x}-${y}.svg`)
        );
      }
      page.push(temp);
      temp = [];
    }
    this.testImages.push(page);
    page = [];
    // console.log(current_page);
    current_page++;
  }
  // console.log(this.testImages);
}
