export default {
	config: {
		rows: 5,
		cols: 5,
		pageTimeAllowed: 35000, // 30 seconds
		lastPageNo: 25,
		breakpoints: [5, 10, 15],
	},
	pageTimeLeft: 30,
	pageTimerTimeout: null,
	pageTimeSpent: 0,
	pageStartTime: 0,
	testTimeOut: "",
	testPaused: true,
	testImages: [],
	testRecord: [],
	currentPage: 1,
	inTransition: true,
	animationTimelines: [],
	exitTimelines: [],
	progressBarKey: 0,
	memoryAnswers: [[], [], [], [], []],
	panelMountedOnce: false,
	skipInterval: null,
};
